<template>
  <main :class="$style.section">
    <div :class="$style.wrapper">
      <icon :class="$style.logo" name="logo" />
      <router-link to="/" :class="[$style.link, $style.linkLogo]">
        <icon :class="$style.linkLogoIcon" name="left" />
        <span :class="$style.linkLogoText">{{ $t('rules.toMain') }}</span>
      </router-link>
    </div>
    <div v-if="locale === 'RU'" :class="$style.content">
      <h2 :class="$style.title">О компании</h2>
      <p :class="$style.paragraph">
        <span>Наш проект</span> Supster — программное обеспечение, позволяющее создать собственную
        децентрализованную соц.сеть в виде мобильного приложения, которое можно установить как с
        Google Play и Apple Store, так и с любого сайта за 1 клик.
      </p>
      <p :class="$style.paragraph">С 2021 года стали резидентами Сколково.</p>
      <p :class="$style.paragraph">В 2022 году представили свой продукт на Tech Week Moscow.</p>
      <br />
      <p :class="$style.paragraph">
        <span>Наш сайт</span> Supster.com представляет собой децентрализованную социальную сеть и
        конструктор мобильных приложений. Эта инновационная платформа позволяет пользователям за
        считанные минуты создавать веб-приложения с разнообразным функционалом — от e-commerce до
        блогов и платных подписок.
      </p>
      <p :class="$style.paragraph">
        Платформа особенно эффективна для малого и среднего предпринимательства, стремящихся
        укрепить связи с клиентами и перенести свой бизнес в мобильное пространство.
      </p>
      <p :class="$style.paragraph">Мы - первая в мире платформа для бизнеса и лидеров мнений!</p>
      <br />
      <p :class="$style.paragraph"><span>Практическое применение и кейсы:</span></p>
      <p :class="$style.paragraph">
        Не смотря на то, что основной функционал платформы находится еще в стадии бета-тестирования,
        уже любой пользователям может создавать веб-приложения с интегрированными чатами и
        нейросетями.
      </p>
      <p :class="$style.paragraph">
        Например: <a href="https://marketolog-ai.supster.me">marketolog-ai.supster.me</a> — это
        веб-приложение, созданное на нашей платформе. И оно служит помощником для маркетологов:
      </p>
      <img :class="$style.image" src="@/assets/images/about.png" alt="chats" draggable="false" />
      <p :class="$style.paragraph">
        Данное веб-приложение помогает решать маркетинговые задачи и оптимизировать свое время при
        помощи нейросетей.
      </p>
      <p :class="$style.paragraph">
        Данный подход позволяет найти применение во множестве других сфер и задач.
      </p>
      <br />
      <p :class="$style.paragraph"><span>Технологический стек:</span></p>
      <ul :class="$style.list">
        <li :class="$style.item">
          Backend: Golang и PHP Symfony. Эти технологии были выбраны за их надежность,
          производительность и масштабируемость.
        </li>
        <li :class="$style.item">
          PWA: Vue. Этот фреймворк выбран для создания динамичных и интерактивных пользовательских
          интерфейсов.
        </li>
        <li :class="$style.item">
          CRM и Admin: Vue. Обеспечивает гладкое и интуитивно понятное управление пользователями.
        </li>
        <li :class="$style.item">
          iOS и Android: Flutter, Kotlin и Java. Эти языки и фреймворки позволяют нам обеспечить
          безупречную работу на различных платформах.
        </li>
      </ul>
      <br />
      <p :class="$style.paragraph"><span>Контакты</span></p>
      <p :class="$style.paragraph">Тел. : 8(800)500-75-97</p>
      <p :class="$style.paragraph">Эл. почта: info@supster.me</p>
      <p :class="$style.paragraph">
        Адрес: 125039, г. Москва, наб. Пресненская, д. 10, стр. 2, помещ. 97, ком. 2, оф. 254
      </p>
      <br />
      <p :class="$style.paragraph"><span>Реквизиты</span></p>
      <p :class="$style.paragraph">Наименование организации: ООО «СП СОЛЮШЕН»</p>
      <p :class="$style.paragraph">ИНН/КПП: 9703059075/770301001</p>
      <p :class="$style.paragraph">ОГРН: 1217700551529</p>
      <p :class="$style.paragraph">Номер счёта: 40702810755000070659</p>
      <p :class="$style.paragraph">Наименование банка: Северо-Западный Банк ПАО Сбербанк</p>
      <p :class="$style.paragraph">Корреспондентский счёт: 30101810500000000653</p>
      <p :class="$style.paragraph">БИК: 044030653</p>
      <p :class="$style.paragraph">
        Адрес: 125039, г. Москва, наб. Пресненская, д. 10, стр. 2, помещ. 97, ком. 2, оф. 254
      </p>
      <p :class="$style.paragraph">Тел. : 8(800)500-75-97</p>
      <p :class="$style.paragraph">
        ОКВЭД: 62.01 Разработка компьютерного программного обеспечения
      </p>
      <p :class="$style.paragraph">Код ОКАТО: 45286575000</p>
      <p :class="$style.paragraph">Код организации по ОКПО: 48837670</p>
    </div>
    <div v-if="locale !== 'RU'" :class="$style.content">
      <h2 :class="$style.title">About the company</h2>
      <p :class="$style.paragraph">
        <span>Our project </span>, Supster, is a software that allows to create your own
        decentralized social network in the form of a mobile app, which can be installed from Google
        Play and Apple Store, as well as from any website with 1 click.
      </p>
      <p :class="$style.paragraph">We have been residents of Skolkovo since 2021.</p>
      <p :class="$style.paragraph">In 2022, we presented our product at Tech Week Moscow.</p>
      <br />
      <p :class="$style.paragraph">
        <span> Our website</span> Supster.com is a decentralized social network and mobile
        application builder. This innovative platform allows users to create web applications with
        diverse functionality — from e-commerce to blogs and paid subscriptions in a matter of
        minutes.
      </p>
      <p :class="$style.paragraph">
        The platform is particularly effective for small and medium-sized businesses who are looking
        to strengthen their relationships with customers and migrate their business to the mobile
        space.
      </p>
      <p :class="$style.paragraph">
        We are the world's first platform for business and thought leaders!
      </p>
      <br />
      <p :class="$style.paragraph"><span>Practical application and case studies:</span></p>
      <p :class="$style.paragraph">
        Despite the fact that the main functionality of the platform is still in beta testing stage,
        any user can already create web applications with integrated chats and neural networks.
      </p>
      <p :class="$style.paragraph">
        For example: <a href="https://marketolog-ai.supster.me">marketolog-ai.supster.me</a> — it’s
        a web application created on our platform and serves as a helper for marketers.
      </p>
      <img :class="$style.image" src="@/assets/images/about.png" alt="chats" draggable="false" />
      <p :class="$style.paragraph">
        This web application helps to solve marketing tasks and optimize time using neural networks.
      </p>
      <p :class="$style.paragraph">
        This approach can be applied in a lot of other fields and tasks.
      </p>
      <br />
      <p :class="$style.paragraph"><span>Technology stack:</span></p>
      <ul :class="$style.list">
        <li :class="$style.item">
          Backend: Golang and PHP Symfony. These technologies were chosen for their reliability,
          performance, and scalability.
        </li>
        <li :class="$style.item">
          PWA: Vue. This framework was chosen for creating dynamic and interactive user interfaces.
        </li>
        <li :class="$style.item">
          CRM and Admin: Vue. Provides smooth and intuitive user management.
        </li>
        <li :class="$style.item">
          iOS and Android: Flutter, Kotlin, and Java. These languages and frameworks allow us to
          ensure flawless operation on various platforms.
        </li>
      </ul>
      <br />
      <p :class="$style.paragraph"><span>Contact Information</span></p>
      <p :class="$style.paragraph">Phone No.: 8(800)500-75-97</p>
      <p :class="$style.paragraph">Email: info@supster.me</p>
      <p :class="$style.paragraph">
        Address: 125039, Moscow, Presnenskaya Embankment, House 10, Building. 2, Room 97, Room 2,
        Office 254
      </p>
      <br />
      <p :class="$style.paragraph"><span>Banking Details</span></p>
      <p :class="$style.paragraph">Organization name: SP SOLUTION, LLC</p>
      <p :class="$style.paragraph">TIN/KPP: 9703059075/770301001</p>
      <p :class="$style.paragraph">OGRN: 1217700551529</p>
      <p :class="$style.paragraph">Account number: 40702810755000070659</p>
      <p :class="$style.paragraph">Bank name: North-West Bank of Sberbank PJSC</p>
      <p :class="$style.paragraph">Correspondent account: 30101810500000000653</p>
      <p :class="$style.paragraph">BIC: 044030653</p>
      <p :class="$style.paragraph">
        Address: 125039, Moscow, Presnenskaya Embankment, House 10, Building. 2, Room 97, Room 2,
        Office 254
      </p>
      <p :class="$style.paragraph">Phone No. : 8(800)500-75-97</p>
      <p :class="$style.paragraph">OKVED: 62.01 Computer software development</p>
      <p :class="$style.paragraph">OKATO code: 45286575000</p>
      <p :class="$style.paragraph">OKPO code for the organization: 48837670</p>
    </div>
    <div :class="$style.wrapper">
      <p :class="[$style.link, $style.date]">{{ `© ${new Date().getFullYear()}. Supster` }}</p>
      <a :class="$style.link" target="_blank">{{ $t('about') }}</a>
    </div>
  </main>
</template>
<script>
import i18n from '@/plugins/i18n'

export default {
  name: 'About',
  computed: {
    locale() {
      const params = new URL(document.location).searchParams
      const locale = params.get('locale')
      return locale
    },
  },
  mounted() {
    const params = new URL(document.location).searchParams
    const locale = params.get('locale')
    i18n.locale = locale
  },
}
</script>
<style lang="scss" module>
main {
  height: auto !important;
}
.section {
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: $padding * 1.5;
  background-color: $band-light;
  box-sizing: border-box;

  @include media('mobile') {
    padding: $padding;
  }
}

.image {
  max-width: 100%;
}

.wrapper {
  display: grid;
  grid-auto-flow: column;
  align-self: center;
  justify-content: space-between;
}

.logo {
  width: 92px;
  height: 28px;
  color: $primary;
  --logo: #3dffc0;
}

.link {
  position: relative;
  z-index: 2;
  align-self: center;
  color: $primary;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;

  &:hover {
    opacity: 0.7;

    svg {
      transform: translateX(-5px);
    }
  }

  &Logo {
    display: grid;
    grid-template-columns: 24px auto;
    column-gap: 8px;

    &Icon {
      align-self: center;
      width: 24px;
      height: 4px;
      margin-top: -2px;
      color: $primary;
      transition: transform $transition;
    }

    &Text {
      align-self: center;
      color: $primary;
    }
  }
}

.date {
  color: $primary;

  &:hover {
    opacity: 1;
  }
}

.content {
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  background-color: white;
  box-shadow: 15px 15px 60px rgba(39, 20, 96, 0.1);
  border-radius: 8px;
  padding: 120px 96px;
  min-height: 88vh;

  @include media('mobile') {
    padding: 24px;
  }
}

.title {
  margin: 0 0 48px 0;
  font-size: 48px;
  line-height: 1.21;
  color: $primary;
  letter-spacing: -0.05em;

  @include media('mobile') {
    font-size: 32px;
    margin: 0 0 24px 0;
  }
}

.subtitle {
  margin: 0 0 48px 0;
  font-size: 24px;
  line-height: 1.21;
  color: $primary;
  letter-spacing: -0.05em;

  @include media('mobile') {
    font-size: 16px;
    margin: 0 0 24px 0;
  }
}

.paragraph {
  margin: 0 0 16px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  color: $primary;

  @include media('mobile') {
    font-size: 13px;
  }

  a {
    color: $primary;
  }

  span {
    color: $primary;
    font-weight: 600;
  }
}

.shadow {
  opacity: 0.4;
  padding: 0 0 0 16px;
}

.text {
  margin: 0 0 24px 0;
  padding: 20px 24px;
  border-radius: 8px;
  background-color: $band-light;
  color: $primary;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;

  @include media('mobile') {
    font-size: 13px;
    margin: 0 0 16px 0;
  }

  span {
    font-weight: 900;
    color: $primary;
  }
}

.list {
  margin: 0 0 16px 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;

  .item {
    position: relative;
    font-size: 14px;
    line-height: 1.7;
    margin: 0 0 8px 0;
    color: $primary;
    font-weight: 400;
    padding: 0 0 0 24px;

    @include media('mobile') {
      font-size: 13px;
    }

    &::before {
      position: absolute;
      top: 12px;
      left: 0px;
      content: '';
      width: 8px;
      height: 2px;
      background-color: $secondary;
    }
  }
}
</style>
